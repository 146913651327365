import { Button, Col, Divider, Row, Typography } from 'antd';
import { PageProps } from 'gatsby';
import React from 'react';

import Container from '@/components/Container';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';

const buyButtonsDimensions = {
  width: 300,
  height: 125,
};

const buyButtonsProps = {
  className: 'rounded-lg shadow-lg mx-4 mb-2',
  type: 'link' as any,
  rel: 'noopener noreferrer nofollow',
  target: '_blank',
};

const BuyPage: React.FC<PageProps> = ({ uri }) => {
  return (
    <Layout>
      <SEO
        title="Где купить продукцию"
        description="Вы можете приобрести актисептики и косметику Rasept в ближайшем магазине или аптеке. Узнайте о всех вариантах покупки."
        image={null}
        uri={uri}
      />
      <Container style={{ width: 'auto' }}>
        <Typography.Title className="text-center my-8" level={1}>
          Где купить нашу продукцию?
        </Typography.Title>
        <Row>
          <Col flex="auto">
            <section className="prose prose-xl max-w-none mb-4 mx-auto">
              <Typography.Paragraph>
                Если в ближайшей к вам аптеке или магазине нет продукции
                «Rasept», покупайте её на онлайн-площадках:
              </Typography.Paragraph>
            </section>
            <Button
              {...buyButtonsProps}
              icon={
                <img
                  className="w-full h-full p-4"
                  src="/icons/distributors/wildberries.svg"
                  alt="«Wildberries»"
                />
              }
              style={{
                ...buyButtonsDimensions,
                background:
                  'linear-gradient(to right,#cb11ab 0,#481173 100%) no-repeat',
              }}
              href="https://www.wildberries.ru/seller/29546"
              title="Наш брэнд на «Wildberries»"
            />
            <Button
              {...buyButtonsProps}
              icon={
                <img
                  className="w-full h-full p-4"
                  src="/icons/distributors/yandex-market.svg"
                  alt="«Яндекс.Маркет»"
                />
              }
              style={{
                ...buyButtonsDimensions,
                background: '#fff',
              }}
              href="https://market.yandex.ru/business--npo-rasept/739038"
              title="Наш брэнд на «Яндекс.Маркете»"
            />
            <Button
              {...buyButtonsProps}
              icon={
                <img
                  className="w-full h-full p-4"
                  src="/icons/distributors/aliexpress.svg"
                  alt="«AliExpress»"
                />
              }
              style={{
                ...buyButtonsDimensions,
                background: '#e43225',
              }}
              href="https://s.click.aliexpress.com/e/_pvyoX0p"
              title="Наш брэнд на «AliExpress»"
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col>
            <section className="prose max-w-none mb-2">
              <Typography.Paragraph>
                Если же у вас возникли какие-либо вопросы, то всегда можете
                задать их нам через мессенджеры:
              </Typography.Paragraph>
            </section>
            <Row justify="space-around">
              <Col>
                <Button
                  icon={
                    <img
                      className="anticon"
                      src="/icons/social/vk.svg"
                      alt="Написать нам «ВКонтакте»"
                      style={{ height: 21, marginBottom: -7 }}
                    />
                  }
                  className="m-2"
                  shape="round"
                  size="large"
                  href="https://vk.com/im?sel=-137607669"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  Написать нам «ВКонтакте»
                </Button>
              </Col>
              <Col>
                <Button
                  icon={
                    <img
                      className="anticon"
                      src="/icons/social/messenger.svg"
                      alt="Написать нам в «Messenger»"
                      style={{ height: 21, marginBottom: -7 }}
                    />
                  }
                  className="m-2"
                  shape="round"
                  size="large"
                  href="https://m.me/RaseptCom"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  Написать нам в «Messenger»
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default BuyPage;
